<template>
  <ui-component-modal
    modalTitle="Set channel publication"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveOptionChannels"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <table class="table is-fullwidth is-narrow">
        <thead>
          <tr>
            <th width="50">Active</th>
            <th>
              Channel
            </th>
            <th>
              Is public
            </th>
          </tr>
        </thead>
        <tbody
          name="fade"
          is="transition-group"
          mode="out-in"
          :duration="{ enter: 300, leave: 300 }"
        >
          <tr v-for="channel in editChannels" :key="channel.ChannelId">
            <td width="50">
              <input
                type="checkbox"
                :id="'isActive_' + channel.ChannelId"
                v-model="channel.IsActive"
                :checked="channel.IsActive"
              />
            </td>
            <td>
              <span
                ><label :for="'isActive_' + channel.ChannelId">{{
                  channel.ChannelName
                }}</label></span
              >
            </td>
            <td>
              <input
                type="checkbox"
                v-model="channel.IsPublic"
                :disabled="!channel.IsActive"
                :checked="channel.IsActive && channel.IsPublic"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import optionProvider from '@/providers/option'

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      editChannels: [],
      channelId: Number(this.$route.params.channelId),
      locationId: Number(this.$route.params.locationId),
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      mOption: null,
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
    ...mapState('locationStore', ['locationState']),
    ...mapState('optionStore', ['option']),

    channels() {
      let channels = []
      if (this.locationState) {
        channels = this.locationState.Channels.filter(
          (c) => c.ChannelId === this.channelId
        )
      }
      return channels
    },
  },

  created() {
    this.mOption = JSON.parse(JSON.stringify(this.option))

    this.prepData()
  },

  methods: {
    ...mapMutations('optionStore', ['setOption']),

    checkOptionOnChannel(channelId) {
      let result = false

      let channelIndex = this.option.Channels.findIndex(
        (c) => c.ChannelId === channelId
      )
      if (channelIndex > -1) {
        result = true
      }

      return result
    },

    checkOptionActiveOnChannel(channelId) {
      let result = false

      let channelIndex = this.option.Channels.findIndex(
        (c) => c.ChannelId === channelId
      )
      if (channelIndex > -1) {
        result = this.option.Channels[channelIndex].IsPublic
      }

      return result
    },

    prepData() {
      let self = this

      for (let j = 0; j < self.channels.length; j++) {
        self.editChannels.push({
          ChannelId: self.channels[j].ChannelId,
          ChannelName: self.channels[j].ChannelName,
          IsPublic: self.checkOptionActiveOnChannel(self.channels[j].ChannelId),
          IsActive: self.checkOptionOnChannel(self.channels[j].ChannelId),
        })
      }
    },

    saveOptionChannels() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        let channels = self.editChannels.filter((c) => c.IsActive)

        optionProvider.methods
          .saveLocationOptionChannels(
            self.mOption.Id,
            self.mOption.Location.LocationId,
            channels
          )
          .then((response) => {
            self.isSavingSuccess = true

            self.setOption(response.data)

            let t = setTimeout(() => {
              self.onClickCancel()
              clearTimeout(t)
            }, 1500)
          })
          .catch((e) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>
